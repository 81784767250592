<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/product-4.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                <div>
                  <span>
                    มี Certificate จากอาจารย์จุฬาลงกรณ์มหาวิทยาลัยให้ทั้งสองหลักสูตร
                  </span>
                </div>
                <br/>
                {{ price }}
                <br>
                <div class="text-xs-right">
                  จากราคาเต็ม 9,000 บาท
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow mt-24px" >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img v-if="salebox.imgicon"
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else-if="salebox.blank"><br/></v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
                <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                  <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                  <!-- <v-img
                    :src="`${salebox.imgicon}`"
                    width="30px">
                  </v-img>
                </v-col>
                <v-col cols="10" sm="10" md="4">
                  <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                </v-col> -->
              </v-row>
              <br/>
              <!-- <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <v-icon>fas fa-chalkboard-teacher</v-icon>
                </v-col>
                <v-col >
                  <strong>สอนโดย</strong><br/>
                  ผศ.ดร.ฑิตยา หวานวารี<br/>
                  ภาควิชาคณิตศาสตร์และวิทยาการคอมพิวเตอร์ คณะวิทยาศาสตร์ <br/>
                  จุฬาลงกรณ์มหาวิทยาลัย <br/><br/>
                </v-col>
              </v-row> -->
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px pt-0">
              <div class="pd-24px text-content">
                เรียนให้รู้ลึกยิ่งกว่าแบบ Blended Learning จากทฤษฎีมุ่งสู่ปฎิบัติจริงกับ
                <br/><br/>

                “ First Step To Data Scientist ”
                <br/><br/>

                เพราะการเรียนแบบทฤษฎี ไม่อาจทำให้คุณเข้าใจบทเรียนได้ลึกซึ้งมากพอ
                เพื่อการเรียนรู้ที่เข้มข้นยิ่งกว่า ต้องใช้ทั้งภาคทฤษฎีและปฎิบัติ
                เสริมทักษะให้แน่นยิ่งขึ้น !!
                <br/><br/>

                นี่คือคอร์สแพ็คคู่ที่ได้รับการตอบรับดีที่สุดในสายงาน Data Scientist !!!
                <br/><br/>

                Bundle First Step To Data Scientist<br/>
                พัฒนาทักษะ ก้าวทันโลกแห่งข้อมูล ก้าวหน้าในโลกดิจิทัล<br/><br/>

                * มี Certificate จากอาจารย์ จุฬาลงกรณ์มหาวิทยาลัย ให้ทั้งสองหลักสูตร *<br/><br/>

                <strong>1. First Step Of Python Workshop รุ่นที่ 7</strong>
                <br/>
                ภาษา Python มาแรงในกลุ่มคนทำงานในด้าน Data Analytics
                เนื่องจากมี library มากมายที่เกี่ยวข้องกับการจัดการข้อมูล วิเคราะห์ข้อมูล
                ตลอดจนถึงการนำเสนอข้อมูล จึงสามารถนํามาปรับใช้ได้ในหลากหลายงาน
                ไม่ว่าจะเป็น Data Analytics, AI (ปัญญาประดิษฐ์),
                Machine Learning และอื่น ๆ นอกจากนี้ ภาษา Python
                ยังเป็นภาษาที่เหมาะสมสำหรับผู้เริ่มต้นเขียนโปรแกรม
                เนื่องจากมีวากยสัมพันธ์ (Syntax) ที่สั้น กระชับ ตรงไปตรงมา แต่มีประสิทธิภาพสูง
                ความง่ายในการใช้งานแต่มีประสิทธิภาพสูงนี้เองทำให้ภาษา Python
                เป็นภาษาที่ได้รับความนิยมอย่างมากในปัจจุบัน
                <br/><br/>

                <v-responsive :aspect-ratio="16/9" class="mb-4">
                  <iframe
                    src="https://www.youtube.com/embed/0pPsXg4cfE4"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                    allowFullScreen
                  >
                  </iframe>
                </v-responsive>

                <strong>สิ่งที่ผู้เรียนจะได้เรียนรู้ใน Workshop นี้</strong><br/>
                📌ทักษะ Python ขั้นพื้นฐาน ได้แก่ ชนิดของข้อมูลในภาษาโปรแกรม
                นิพจน์ การควบคุมลำดับการทำงานของโปรแกรมซึ่งประกอบไปด้วย
                การทำงานแบบมีเงื่อนไข การทำงานแบบวนซ้ำ<br/>
                📌การประยุกต์ใช้ Python เพื่อช่วยให้งานประจำวันมีความเป็นอัตโนมัติมากขึ้น
                โดยการประมวลผลข้อความและไฟล์ การอ่านไฟล์เพื่อนำเข้าข้อมูลเพื่อนำมาประมวลผล
                และเขียนไฟล์เพื่อส่งออกข้อมูลในรูปแบบที่ต้องการ
                โดยยกตัวอย่างการทำงานกับไฟล์ข้อมูลจริง<br/>
                📌กระบวนการนำ Python ไปใช้กับงานประมวลผลข้อมูล
                ซึ่งเป็นข้อมูลในโลกจริง (Real-world Data)<br/>
                📌ทำโปรเจกต์จริงซึ่งเป็นการนำ Python มาวิเคราะห์และนำเสนอข้อมูล มี
                Workshop กลุ่มเพื่อแลกเปลี่ยนไอเดียกับเพื่อนจากหลากหลายอาชีพ
                ประกาศนียบัตรจาก Achieve+
                <br/><br/>

                <strong>สอนโดย</strong><br/>

                ผศ.ดร.จารุโลจน์ จงสถิตย์วัฒนา (Asst.Prof.Dr.Jaruloj Chongstitvatana)<br/>
                ภาควิชาคณิตศาสตร์และวิทยาการคอมพิวเตอร์
                คณะวิทยาศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย<br/><br/>


                โดยผู้เข้าร่วมเวิร์กชอปทุกท่านจะได้รับประกาศนียบัตรเชิงปฎิบัติการจาก Achieve
                <br/><br/>

                <strong>วัน เวลา</strong><br/>
                - วันที่: 28 มีนาคม 2563<br/>
                - เวลา: 08:00 - 17:30 น.<br/><br/>

                <strong>2. Data Science Pathway 2019</strong><br/>
                แม้ไม่มีพื้นฐานด้าน DATA SCIENCE !!
                เพียงแค่คุณรู้จักประยุกต์ใช้ข้อมูลที่มีเพื่อประโยชน์ทางธุรกิจ<br/>

                เปลี่ยนโลกที่เต็มไปด้วยข้อมูลให้เป็นโลกที่ขับเคลื่อนด้วยข้อมูล
                เพื่อให้องค์กรเป็นผู้ชนะในโลกแห่งการแข่งขันนี้
                รายวิชานี้จะให้มุมมองแบบองค์รวม นําเสนอ Best Practices
                รวมถึงกรณีศึกษาต่าง ๆ
                เพื่อให้ผู้เรียนเข้าใจและรู้วิธีที่จะนำ Data ไปปฏิบัติงานจริง<br/><br/>

                สอนโดยคณาจารย์จากคณะวิศวกรรมศาสตร์
                และคณะพาณิชยศาสตร์และการบัญชี จุฬาลงกรณ์มหาวิทยาลัย<br/><br/>

                <v-responsive :aspect-ratio="16/9" class="mb-4">
                  <iframe
                    src="https://www.youtube.com/embed/WUqP_bO1Fiw"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                    allowFullScreen
                  >
                  </iframe>
                </v-responsive>

                <strong>สิ่งที่จะได้เรียนรู้จากชุดวิชา Data Science</strong><br/>
                📌กรณีศึกษาจากองค์กรชั้นนำ เพื่อเปิดมุมมองภาพรวม
                สร้างความรู้ความเข้าใจของการนำข้อมูลไปประยุกต์ใช้งานจริง<br/>
                📌ศึกษาวิธีการวิเคราะห์ข้อมูล เทคนิคการนำข้อมูลมาใช้
                ขั้นตอนวิธีการทำงานและเครื่องมือที่ใช้<br/>
                📌เรียนรู้เครื่องมือสุดล้ำอย่าง Rapidminer ที่จะช่วยตอบทุกโจทย์คำถามคุณ
                โดยไม่ต้องเขียนโค้ดเอง
                ไม่ว่าจะเป็นการเข้าใจถึงความสัมพันธ์ของข้อมูลเพื่อเข้าใจพฤติกรรมต่าง ๆ ของลูกค้า
                การทำนายข้อมูลที่จะช่วยสนับสนุนการวางแผนและการตัดสินใจทางธุรกิจ<br/>
                📌เรียนรู้ศาสตร์มาแรงอย่าง Python ในระดับเริ่มต้น เช่น การดึงข้อมูลจากเฟซบุ๊ก
                เรียนรู้วิธีการนำเสนอข้อมูลเบื้องต้น เพื่อให้ง่ายต่อการสื่อสารกับผู้อื่น<br/><br/>

                คอร์สออนไลน์ 3 เดือน ที่จะช่วยเปิดโลกและพัฒนาสกิลของคุณให้แข็งแกร่ง
                เพื่อเตรียมพร้อมเพื่อยุค Digital Dristruption อย่างสมบูรณ์แบบที่สุด<br/><br/>

                <strong>Data Science Pathway เหมาะกับใคร</strong><br/>
                คอร์สนี้เหมาะสำหรับคนทำงานทั้งระดับ Executive, Manager และ Operation<br/>
                - ระดับ Executive สามารถให้วิสัยทัศน์ในภาพกว้าง
                เพื่อขับเคลื่อนองค์กรด้วยข้อมูลให้เท่าทันคู่แข่ง<br/>
                - ระดับ Manager สามารถใช้ประโยชน์จากข้อมูล
                เพื่อพัฒนาเทคโนโลยีและส่งเสริมวิสัยทัศน์ขององค์กร<br/>
                - ระดับ Operation สามารถใช้ทักษะจากการเรียนชุดวิชานี้
                เพื่อปรับปรุงงาน Day-to-Day ของแผนกอย่างชาญฉลาดมากยิ่งขึ้น<br/><br/>

                <strong>การันตีด้วยจำนวนผู้เรียนที่มากกว่า 1,800 คนภายใน 4 เดือน</strong><br/><br/>

                <strong>ราคา 7,200 บาท จากราคาปกติ 9,000 บาท</strong><br/><br/>


                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-3.jpg')"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-4.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-1.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-17-image-2.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>
              </v-row>
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col v-if="salebox.imgicon" cols="2">
                    <v-row justify="center">
                      <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'wspw0170010011',
      workshop: [
        { title: 'First Step to Data Scientist รุ่น 3', date: '28/03/2563' },
      ],
      title: 'First Step to Data Scientist รุ่น 3',
      date: '28/03/2563',
      price: '7,200 บาท',
      salebox: [
        {
          title: 'อบรมเชิงปฏิบัติการ (workshop)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'วันเสาร์ที่ 28 มีนาคม 2563', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '08:00 - 17:30 น.', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'ทางทีมงานจะแจ้งให้ทราบอีกครั้ง', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          blank: true,
        },
        {
          title: 'ชุดวิชา (pathway)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 6,
        },
        {
          title: 'แจ้งวันเริ่มเรียนกับทางทีมงาน', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 7,
        },
        {
          title: '3 เดือน', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 8,
        },
        {
          title: 'เรียนออนไลน์', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 9,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 10,
        },

      ],
      isSale: new Date('2020-03-25T10:00:00.000Z') > new Date(),
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
